import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const storeName = 'browserEvent';

const initialState = {
  browserEventsCount: 0,
  browserSearch: '',
  urlCount: 0,
  urlPath: '',
};

const browserEventSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    backForwardBrowserEvent(state, action: PayloadAction<string>) {
      state.browserSearch = action.payload;
      state.browserEventsCount = state.browserEventsCount + 1;
      // console.log('browserEvent. backForwardBrowserEvent', state.browserEventsCount, state.browserSearch);
    },

    urlBrowserEvent(state, action: PayloadAction<string>) {
      state.browserSearch = action.payload;
      state.urlCount = state.urlCount + 1;
      state.urlPath = window.location.href.split('?')[0] || '';
      // console.log('browserEvent. urlBrowserEvent', state.urlCount, state.urlPath, state.browserSearch);
    },
  },
});

export const browserEventActions = {
  backForwardBrowserEvent: browserEventSlice.actions.backForwardBrowserEvent,
  urlBrowserEvent: browserEventSlice.actions.urlBrowserEvent,
};

export const browserEventReducer = browserEventSlice.reducer;
